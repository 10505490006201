<template>
    <section id="bannerHeadEquipment" class="primary py-16">
        <v-img
            dark
            :src="require('@/assets/logos-white.png')"
            class="mx-auto"
            width="15%"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
        />
    </section>
</template>

<script>
export default {
    name: 'SectionHomeBottom',
}
</script>

<style lang="scss">
</style>